import React from 'react'
import type { RFC } from 'common-web'
import { Svg, type SvgProps } from '../../../primitives'

export const Scroll: RFC<SvgProps> = (props) => (
  <Svg width="24" height="24" viewBox="0 0 589 580" {...props}>
    <path
      d="M546.937 393.197V52.1277C546.473 23.5893 523.735 0.619263 495.196 0.619263H139.278C62.4793 1.77936 0.761948 64.6567 0.761948 141.687C0.761948 167.674 7.72256 189.948 18.3955 209.437C27.4443 225.679 41.5975 240.992 55.5187 252.361C59.463 255.609 57.6069 254.217 69.6719 261.642C86.3773 271.851 105.403 276.955 105.403 276.955L105.171 480.901C105.635 490.645 106.563 499.926 109.115 508.511C117.004 537.281 136.958 559.323 164.104 569.996C175.473 574.405 188.234 577.421 201.923 577.653L486.148 578.581C542.761 578.581 588.701 532.641 588.701 475.796C588.933 442.153 572.227 411.991 546.937 393.197Z"
      fill="#FFEEDA"
    />
    <path
      d="M553.897 478.348C552.737 514.775 522.807 544.01 486.147 544.01L290.555 543.314C306.1 525.216 315.613 501.782 315.613 476.26C315.613 436.121 291.715 408.51 291.715 408.51H486.379C523.735 408.51 554.129 438.905 554.129 476.26L553.897 478.348Z"
      fill="#EBC28E"
    />
    <path
      d="M74.0803 223.59C51.5744 202.245 35.797 174.634 35.797 141.919V138.439C37.6532 82.5222 83.5931 37.5104 139.51 35.8863H495.429C504.709 36.3503 512.134 42.8468 512.134 52.3596V353.521C520.255 354.914 524.199 356.074 532.088 358.858C538.352 361.178 546.937 366.051 546.937 366.051V52.3596C546.473 23.8212 523.735 0.851241 495.197 0.851241H139.278C62.4793 2.01134 0.761963 64.8887 0.761963 141.919C0.761963 186.699 21.1798 224.982 54.5906 251.897C56.9108 253.753 58.999 256.073 65.0315 256.073C75.4724 256.073 82.8971 247.72 82.433 238.672C82.201 231.015 78.9527 228.231 74.0803 223.59Z"
      fill="#190602"
    />
    <path
      d="M486.148 373.243H207.028C188.234 373.475 173.153 388.556 173.153 407.35V447.489C173.617 466.051 189.394 481.829 208.188 481.829H228.838V447.489H208.188V408.278C208.188 408.278 213.292 408.278 219.557 408.278C254.824 408.278 280.81 440.993 280.81 476.028C280.81 507.119 252.504 546.794 205.171 543.546C163.176 540.762 140.438 503.406 140.438 476.028V135.887C140.438 120.573 127.909 108.044 112.596 108.044H84.7532V142.847H105.403V476.26C104.243 544.01 153.663 578.117 205.171 578.117L486.38 579.045C542.993 579.045 588.933 533.105 588.933 476.26C588.933 419.415 542.761 373.243 486.148 373.243ZM553.897 478.348C552.737 514.775 522.807 544.01 486.147 544.01L290.555 543.314C306.1 525.216 315.613 501.782 315.613 476.26C315.613 436.121 291.715 408.51 291.715 408.51H486.379C523.735 408.51 554.129 438.905 554.129 476.26L553.897 478.348Z"
      fill="#190602"
    />
    <path
      d="M410.974 149.112H200.532V114.309H410.974C420.487 114.309 428.375 121.966 428.375 131.711C428.375 141.224 420.719 149.112 410.974 149.112Z"
      fill="#190602"
    />
    <path
      d="M410.974 312.918H200.532V278.115H410.974C420.487 278.115 428.375 285.772 428.375 295.516C428.375 305.029 420.719 312.918 410.974 312.918Z"
      fill="#190602"
    />
    <path
      d="M448.097 231.015H200.532V196.212H447.865C457.378 196.212 465.266 203.869 465.266 213.614C465.499 223.126 457.61 231.015 448.097 231.015Z"
      fill="#190602"
    />
  </Svg>
)
