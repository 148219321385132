import { useProjectId } from '@/components'
import { namedURLs, trpc } from '@/lib'
import { type NetworkID, SourceTypeId, ZodExtensions, sqlSlugify } from 'common'
import type { OptionType, RFC } from 'common-web'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { BetterForm, Button, Column, FormControl, FormField, FormItem, FormLabel, Textarea, betterToast } from 'ui'
import { z } from 'zod'
import { ImportABIFromEtherscanWidget } from './import-abi-from-etherscan-widget'

const _relatedOptions: OptionType<string>[] = [
  {
    label: 'Implementation Contract',
    value: 'implementation-contract',
  },
  {
    label: 'Parent Contract',
    value: 'parent-contract',
  },
]

const formSchema = z.object({
  network: ZodExtensions.NetworkID,
  address: z.string(),
  label: z.string().min(1),
  abiAsString: z.string(),
  relatedType: z.string(),
  prefix: z.string().min(1, 'prefix is required'),
})

export const AddRelatedContractTab: RFC<{ network: NetworkID; contractId: string }> = ({ network, contractId }) => {
  const mutate = trpc.sources.evmContractV1.addRelatedContract.useMutation()
  const projectId = useProjectId()

  const navigate = useNavigate()
  const handleSubmit = (values: Required<z.infer<typeof formSchema>>) =>
    mutate.mutateAsync({ projectId, relatedContractId: contractId, ...values }).then((result) => {
      betterToast.success('Contract Added')

      navigate(
        namedURLs.sources.show({
          sourceTypeId: SourceTypeId.EVM_CONTRACT_INDEXING_CLAIM,
          id: result.id,
        }),
      )
    })

  const form = useForm({
    defaultValues: {
      network,
      address: '',
      abiAsString: '[]',
      label: '',
      prefix: '',
    },
  })

  const handleAbiLoaded = React.useCallback(
    (abi: object) => form.setValue('abiAsString', JSON.stringify(abi, null, 2)),
    [form.setValue],
  )
  const watch = form.watch()

  React.useEffect(() => {
    const input = watch.label
    if (input) {
      const [slug, error] = sqlSlugify(input)
      if (!error && slug) {
        form.setValue('prefix', slug)
      }
    }
  }, [watch.label])
  return (
    <div>
      <BetterForm.Provider onSubmit={handleSubmit} form={form}>
        <div className="grid grid-cols-2 gap-lg">
          <BetterForm.Field name="network" label={'Network'} disabled={true} required={true} className="col-span-1" />
          <BetterForm.Field
            autoFocus
            name="address"
            label={'Contract Address'}
            placeholder="0x"
            required={true}
            className="col-span-1"
          />
          {/* <BetterForm.Field
            name="relatedType"
            label="Related Type"
            component={SelectInput}
            disabled={true}
            options={relatedOptions}
          /> */}
          <BetterForm.Field name="label" label={'Contract Name'} required={true} className="col-span-1" />

          <BetterForm.Field
            name="prefix"
            label="Internal Prefix"
            className={'font-mono'}
            description="This prefix will be used internally when referencing this contract"
          />
        </div>

        <FormField
          control={form.control}
          name={'abiAsString'}
          render={() => (
            <FormItem className="col-span-1">
              <FormLabel>Contract ABI</FormLabel>
              <FormControl className="w-full">
                <Column className="w-full space-y-2">
                  <ImportABIFromEtherscanWidget
                    address={form.getValues().address}
                    network={form.getValues().network}
                    onABILoaded={handleAbiLoaded}
                  />

                  <FormField
                    name="abiAsString"
                    control={form.control}
                    render={({ field }) => (
                      <FormControl className="w-full">
                        <Textarea
                          autoComplete="off"
                          placeholder="or paste contract abi here"
                          className="w-full font-mono text-sm h-[200px]"
                          {...field}
                        />
                      </FormControl>
                    )}
                  />
                </Column>
              </FormControl>
            </FormItem>
          )}
        />
        <Button type="submit">Add Related Contract</Button>
      </BetterForm.Provider>
    </div>
  )
}
