import * as Sentry from '@sentry/react'
import { DestinationTypeId, SourceTypeId } from 'common'
import { Navigate, type RouteObject, createBrowserRouter } from 'react-router-dom'
import type { RFC } from 'ui'
import { ProjectGate } from './contexts/project-context'
import { trpc } from './lib'
import { DashboardLayout } from './pages/dashboard/layout'

import ApiKeysPage from './pages/dashboard/api-keys/index.page'
import NewApiKeyPage from './pages/dashboard/api-keys/new.page'
import ShowApiKeyPage from './pages/dashboard/api-keys/show.page'
import ListDestinationsPage from './pages/dashboard/destinations/index.page'
import NewDestinationPage from './pages/dashboard/destinations/new.page'
import NewPostgresManagedDbScreen from './pages/dashboard/destinations/postgres-managed-db/new.page'
import ShowPostgresDestination from './pages/dashboard/destinations/postgres-managed-db/show.page'
import ShowDestinationPage from './pages/dashboard/destinations/show.page'
import DashboardOverviewPage from './pages/dashboard/index.page'
import LinksIndexPage from './pages/dashboard/links/index.page'
import NewLinkPage from './pages/dashboard/links/new.page'
import ShowLinkPage from './pages/dashboard/links/show.page'
import PerformancePage from './pages/dashboard/performance.page'
import PlanPage from './pages/dashboard/plan.page'
import PostCheckoutPage from './pages/dashboard/post-checkout'
import ProjectsIndexPage from './pages/dashboard/projects/index.page'
import NewProjectPage from './pages/dashboard/projects/new.page'
import BillingCard from './pages/dashboard/settings/billing-card.page'
import ProjectSettingsPage from './pages/dashboard/settings/project-settings.page'
import ShowSighashPage from './pages/dashboard/sources/evm-contract-v1/[id]/[sighash]/index.page'
import ShowEVMContractPage from './pages/dashboard/sources/evm-contract-v1/[id]/index.page'
import NewEVMContractPage from './pages/dashboard/sources/evm-contract-v1/new.page'
import ListSourcesPage from './pages/dashboard/sources/index.page'
import NewSourcePage from './pages/dashboard/sources/new.page'
import ShowSourcePage from './pages/dashboard/sources/show.page'
import TeamPage from './pages/dashboard/team.page'
import TransformsIndexPage from './pages/dashboard/transforms/index'
import UsagePage from './pages/dashboard/usage/index.page'
import UserPage from './pages/dashboard/user.page'
import AcceptInvitationPage from './pages/invitations/accept.page'

// https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter) as any

const NewProjectPageWrapper: RFC = ({ children }) => {
  const auth = trpc.auth.whoami.useQuery()

  if (auth.data?.projects.length === 0) {
    return children
  }
  return <ProjectGate>{children}</ProjectGate>
}

export const breadcrumbHandle = (name: string) => ({ handle: { breadcrumb: { name } } })

const dashboardRoutes: RouteObject[] = [
  {
    path: '/projects/new',
    element: (
      <NewProjectPageWrapper>
        <DashboardLayout />
      </NewProjectPageWrapper>
    ),
    children: [
      {
        path: '',
        element: <NewProjectPage />,
        ...breadcrumbHandle('New'),
      },
    ],
  },

  {
    path: '/invitations/:key/accept/',
    element: <AcceptInvitationPage />,
    ...breadcrumbHandle('Accept Invitation'),
  },

  {
    element: (
      <ProjectGate>
        <DashboardLayout />
      </ProjectGate>
    ),
    ...breadcrumbHandle('Dashboard'),

    children: [
      {
        path: '',
        element: <Navigate to="sources" />,
        ...breadcrumbHandle('Sources'),
      },
      {
        path: 'api-overview',
        element: <DashboardOverviewPage />,
        ...breadcrumbHandle('Overview'),
      },
      {
        path: 'usage',
        element: <UsagePage />,
        ...breadcrumbHandle('Usage'),
      },
      {
        path: 'performance',
        element: <PerformancePage />,
      },
      {
        path: 'billing',
        element: <BillingCard />,
      },
      {
        path: 'plan',
        element: <PlanPage />,
      },
      {
        path: 'post-checkout',
        element: <PostCheckoutPage />,
      },
      {
        path: 'api-keys',
        ...breadcrumbHandle('API Keys'),

        children: [
          {
            path: '',
            element: <ApiKeysPage />,
            ...breadcrumbHandle('List'),
          },
          {
            path: ':id',
            element: <ShowApiKeyPage />,
            ...breadcrumbHandle('Detail'),
          },
          {
            path: 'new',
            element: <NewApiKeyPage />,
            ...breadcrumbHandle('New'),
          },
        ],
      },
      {
        path: 'team',
        element: <TeamPage />,
        ...breadcrumbHandle('Team'),
      },

      {
        path: 'user',
        element: <UserPage />,
        ...breadcrumbHandle('User'),
      },
      {
        path: 'settings',
        element: <ProjectSettingsPage />,
        ...breadcrumbHandle('Settings'),
      },
      {
        path: 'projects',
        ...breadcrumbHandle('Projects'),
        children: [
          {
            path: '',
            element: <ProjectsIndexPage />,
          },
        ],
      },
      {
        path: 'sources',
        ...breadcrumbHandle('Sources'),
        children: [
          {
            path: '',
            element: <ListSourcesPage />,
            loader: ListSourcesPage.loader,
          },
          {
            path: ':id',
            element: <ShowSourcePage />,
            loader: ShowSourcePage.loader,
            ...breadcrumbHandle('Detail'),
          },
          {
            path: 'new',
            children: [
              {
                ...breadcrumbHandle('New'),
                path: '',
                element: <NewSourcePage />,
              },
            ],
          },
          {
            path: `${SourceTypeId.EVM_CONTRACT_INDEXING_CLAIM}/new`,
            element: <NewEVMContractPage />,
            ...breadcrumbHandle('New EVM Contract'),
          },
          {
            path: SourceTypeId.EVM_CONTRACT_INDEXING_CLAIM,
            element: <NewEVMContractPage />,
            ...breadcrumbHandle('New EVM Contract'),
          },
          {
            path: `${SourceTypeId.EVM_CONTRACT_INDEXING_CLAIM}/:id`,
            ...breadcrumbHandle('Show EVM Contract'),
            children: [
              {
                path: '',
                element: <ShowEVMContractPage />,
                loader: ShowEVMContractPage.loader,
              },
              {
                path: ':fragmentId',
                element: <ShowSighashPage />,
                ...breadcrumbHandle('Fragment Detail'),
              },
            ],
          },
        ],
      },
      {
        path: 'transforms',
        ...breadcrumbHandle('Transforms'),
        children: [
          {
            path: '',
            element: <TransformsIndexPage />,
          },
        ],
      },
      {
        path: 'links',
        ...breadcrumbHandle('Links'),
        children: [
          {
            path: '',
            element: <LinksIndexPage />,
          },
          {
            path: 'new',
            element: <NewLinkPage />,
            loader: NewLinkPage.loader,
          },
          {
            path: ':id',
            element: <ShowLinkPage />,
            loader: ShowLinkPage.loader,
          },
        ],
      },
      {
        path: 'destinations',
        ...breadcrumbHandle('Destinations'),
        children: [
          {
            loader: ListDestinationsPage.loader,
            path: '',
            element: <ListDestinationsPage />,
          },
          {
            path: 'new',
            children: [
              {
                ...breadcrumbHandle('New'),
                path: '',
                element: <NewDestinationPage />,
              },
            ],
          },
          {
            path: `${DestinationTypeId.MANAGED_UPSTREAM_POSTGRES}/new`,
            element: <NewPostgresManagedDbScreen />,
            ...breadcrumbHandle('New Postgres Managed Database'),
          },
          {
            path: `${DestinationTypeId.MANAGED_UPSTREAM_POSTGRES}/:id`,
            element: <ShowPostgresDestination />,
            loader: ShowPostgresDestination.loader,
            ...breadcrumbHandle('Managed Postgres Server'),
          },

          {
            path: ':id',
            element: <ShowDestinationPage />,
            loader: ShowDestinationPage.loader,
            ...breadcrumbHandle('Foreign Data Wrapper'),
          },
        ],
      },
    ],
  },
] as RouteObject[]

export const router: ReturnType<typeof sentryCreateBrowserRouter> = sentryCreateBrowserRouter(dashboardRoutes, {})
