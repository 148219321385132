import { ButtonLink, Page, useProjectId } from '@/components'
import { trpc } from '@/lib'
import { createColumnHelper } from '@tanstack/react-table'
import clsx from 'clsx'
import type { RFC } from 'common-web'
import { DestinationTypeId } from 'common/src/chainpipe/types'
import { Plus } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { BetterTable, DestinationLogo, NetworkLabel } from 'ui'
import type { ContractAbiFragmentIndexingClaimToManagedPostgresView } from '../../../../../../packages/models/src/chainpipe'
const columnHelper = createColumnHelper<ContractAbiFragmentIndexingClaimToManagedPostgresView>()

const columns = [
  columnHelper.display({
    header: 'Network',
    cell: (props) => {
      const obj = props.row.original
      return (
        <div className="space-y-1">
          <NetworkLabel value={obj.contractAbiFragmentIndexingClaim.contractIndexingClaim.network} />
          {/* <div>{obj.contractAbiFragmentIndexingClaim.contractIndexingClaim.network}</div> */}
        </div>
      )
    },
  }),

  columnHelper.display({
    header: 'Source',
    cell: (props) => {
      const obj = props.row.original
      return (
        <div className="flex space-x-sm items-center">
          <div className="space-y-1">
            <div className="">{obj.contractAbiFragmentIndexingClaim?.fullName}</div>
            <div className="text-slate-600">{obj.contractAbiFragmentIndexingClaim.contractIndexingClaim.address}</div>
          </div>
        </div>
      )
    },
  }),

  columnHelper.display({
    header: 'Destination',
    cell: (props) => {
      const obj = props.row.original
      return (
        <div className="space-y-1">
          <div className="flex items-center space-x-sm">
            <DestinationLogo destinationType={DestinationTypeId.MANAGED_UPSTREAM_POSTGRES} variant="xs" />
            <div>{obj.tableName}</div>
          </div>
        </div>
      )
    },
  }),

  columnHelper.display({
    header: 'Created At',
    cell: (props) => {
      const obj = props.row.original
      return <div>{obj.createdAt?.toString()}</div>
    },
  }),

  // columnHelper.display({
  //   header: 'Debug',
  //   cell: (props) => {
  //     const obj = props.row.original
  //     return <Debug value={obj} />
  //   },
  // }),
  // columnHelper.display({
  //   header: 'Created At',
  //   cell: (props) => {
  //     const obj = props.row.original
  //     return <div>{obj?.createdAt?.toDateString()}</div>
  //   },
  // }),
]

const LinksIndexPage: RFC = ({ className = undefined, ...props }) => {
  const projectId = useProjectId()

  const { data } = trpc.linksRouter.list.useQuery({
    projectId,
  })
  const navigate = useNavigate()

  return (
    <Page
      action={
        <ButtonLink leftIcon={<Plus className={'w-4 h-4'} />} to="/links/new">
          New Link
        </ButtonLink>
      }
      title="Links"
      className={clsx(className)}
      {...props}
    >
      <BetterTable
        hoverable
        // onNavigate={navigate}
        // @ts-ignore
        data={data}
        columns={columns}
      >
        Links are how you connect sources and destinations
        <br />
      </BetterTable>
    </Page>
  )
}

export default LinksIndexPage
