import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const useUrlSearchParams = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const getSearchParams = () => {
    return new URLSearchParams(location.search)
  }

  const setSearchParams = (params: Record<string, string | number | null>) => {
    const searchParams = getSearchParams()
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString())
      } else {
        searchParams.delete(key)
      }
    })
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true })
  }

  return { getSearchParams, setSearchParams }
}

export const useUrlSearchParam = (name: string) => {
  return useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }, [location.search])
}
