import React from 'react'
import type { RFC } from 'common-web'
import { Svg, type SvgProps } from '../../../primitives'

export const Blast: RFC<SvgProps> = (props) => (
  <Svg width="24" height="24" viewBox="0 0 252 166" fill="none" {...props}>
    <path
      d="M197.902 81.513L237.51 61.7769L251.163 19.8713L223.857 0H42.0407L0 31.2264H213.718L202.363 66.373H116.66L108.414 92.057H194.117L170.055 166L210.204 146.129L224.533 101.79L197.632 82.0538L197.902 81.513Z"
      fill="black"
    />
    <path
      d="M60.4215 134.233L85.1593 57.1807L57.7179 36.6334L16.4883 166H170.052L180.325 134.233H60.4215Z"
      fill="black"
    />
  </Svg>
)
