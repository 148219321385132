import '@fontsource/roboto-mono'
import ReactDOM from 'react-dom/client'
import '../../../packages/ui/src/style/style.css'
import '@fontsource/inter'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import { DashboardApp } from './application'
import { wireUpFrontendTracing } from './lib/monitoring'

/**
 * This should run before react gets bootstrapped
 * @see https://docs.sentry.io/platforms/javascript/guides/react/
 */

wireUpFrontendTracing()

const el = document.getElementById('root')
if (!el) {
  throw new Error('Could not find root element id')
}
const root = ReactDOM.createRoot(el)

root.render(<DashboardApp />)
