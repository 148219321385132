import React from 'react'
import type { RFC } from 'common-web'
import { Svg, type SvgProps } from '../../../primitives'

export const Cyber: RFC<SvgProps> = (props) => (
  <Svg width="24" height="24" viewBox="0 0 213 212" {...props}>
    <path
      d="M106.254 68.1351C127.211 68.1351 144.203 85.0836 144.203 105.988C144.203 126.892 127.211 143.841 106.254 143.841C85.2977 143.841 68.3065 126.892 68.3065 105.988C68.3065 85.0836 85.2977 68.1351 106.254 68.1351ZM106.254 0C91.9386 0 78.0211 2.81057 64.8722 8.35601C52.207 13.6933 40.8511 21.3301 31.1174 31.0393C21.3837 40.7486 13.7277 52.076 8.37703 64.7094C2.81764 77.8159 0 91.7079 0 105.988C0 120.268 2.81764 134.15 8.37703 147.266C13.7277 159.9 21.3837 171.227 31.1174 180.936C40.8511 190.646 52.207 198.282 64.8722 203.62C78.0117 209.165 91.9386 211.976 106.254 211.976C120.57 211.976 134.488 209.165 147.637 203.62C160.302 198.282 171.658 190.646 181.392 180.936C191.125 171.227 198.781 159.9 204.132 147.266C209.691 134.16 212.509 120.268 212.509 105.988C212.509 91.7079 209.691 77.8254 204.132 64.7094C198.781 52.076 191.125 40.7486 181.392 31.0393C171.658 21.3301 160.302 13.6933 147.637 8.35601C134.497 2.81057 120.57 0 106.254 0Z"
      fill="#B7F7B0"
    />
    <path
      d="M106.255 68.135C127.212 68.135 144.203 85.0836 144.203 105.988C144.203 126.892 127.212 143.841 106.255 143.841C85.2984 143.841 68.3072 126.892 68.3072 105.988C68.3072 85.0836 85.2984 68.135 106.255 68.135ZM106.255 22.7117C60.2242 22.7117 22.7695 60.0724 22.7695 105.988C22.7695 151.903 60.2242 189.264 106.255 189.264C152.286 189.264 189.741 151.903 189.741 105.988C189.741 60.0724 152.286 22.7117 106.255 22.7117Z"
      fill="#07DC10"
    />
    <path
      d="M106.256 68.135C127.213 68.135 144.204 85.0836 144.204 105.988C144.204 126.892 127.213 143.841 106.256 143.841C85.2991 143.841 68.3079 126.892 68.3079 105.988C68.3079 85.0836 85.2991 68.135 106.256 68.135ZM106.256 45.4233C72.7763 45.4233 45.5391 72.5922 45.5391 105.988C45.5391 139.383 72.7763 166.552 106.256 166.552C139.736 166.552 166.973 139.383 166.973 105.988C166.973 72.5922 139.736 45.4233 106.256 45.4233Z"
      fill="#0C9B00"
    />
    <path
      d="M106.253 68.135C127.21 68.135 144.201 85.0836 144.201 105.988C144.201 126.892 127.21 143.841 106.253 143.841C85.2959 143.841 68.3047 126.892 68.3047 105.988C68.3047 85.0836 85.2959 68.135 106.253 68.135Z"
      fill="#EAEAEA"
    />
  </Svg>
)
