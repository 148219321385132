import type { DestinationTypeId, SourceTypeId } from 'common'

export const namedURLs = {
  homePage: '',
  sources: {
    index: () => '/sources',
    add: () => '/sources/new',
    list: () => '/sources',

    new: (params: { sourceTypeId: SourceTypeId }) => `/sources/${params.sourceTypeId}/new`,

    show: (params: { sourceTypeId: SourceTypeId; id: string }) => `/sources/${params.sourceTypeId}/${params.id}`,

    showABIFragmentDetail: (params: {
      sourceID: SourceTypeId.EVM_CONTRACT_INDEXING_CLAIM
      id: string
      claimID: string
    }) => `/sources/${params.sourceID}/${params.id}/${params.claimID}`,
  },
  projects: {
    new: () => '/projects/new',
  },
  destinations: {
    add: () => '/destinations/new',

    new: (params: { destinationID: DestinationTypeId }) => `/destinations/${params.destinationID}/new`,

    show: (params: { id: string }) => `/destinations/${params.id}`,
  },
}

export function getAuthUrls(apex: string) {
  const authDomain = `auth.${apex}`
  // protocol is like http: -- so we need the double slash
  const authUrlPrefix = `https://${authDomain}`
  const urlForRedirect = new URL(window.location.href)
  urlForRedirect.pathname = namedURLs.homePage
  const urlEncodedRedirect = urlForRedirect.toString()

  function appendQueryParams(baseUrl: string) {
    const originalUrl = new URL(baseUrl)
    const searchParams = new URLSearchParams({ next: urlEncodedRedirect })
    originalUrl.search = searchParams.toString()
    return originalUrl.toString()
  }

  const loginUrl = appendQueryParams(`${authUrlPrefix}/api/auth/redirect`)

  return {
    /**
     * @see apps/auth-api/src/routes/credentials.ts
     */
    login: loginUrl,
    resetPassword: `${authUrlPrefix}/reset`,
    logout: `${authUrlPrefix}/api/auth/logout`,
    credentials: {
      signUp: appendQueryParams(`${authUrlPrefix}/api/auth/signup`),
      login: appendQueryParams(`${authUrlPrefix}/api/auth/login`),
    },
    google: {
      login: appendQueryParams(`${authUrlPrefix}/api/login/google`),
    },
    github: {
      login: appendQueryParams(`${authUrlPrefix}/api/login/github`),
    },
  }
}
