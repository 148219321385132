import { Page } from '@/components'
import { type RouterInputs, trpc } from '@/lib'
import React from 'react'
import { useForm } from 'react-hook-form'
import { BetterForm, Button, Spinner, clsx } from 'ui'
import { useNewProjectCreation } from '../../../contexts/project-context'

type ICreateProjectInputs = RouterInputs['dashboard']['projects']['create']

const NewProjectPage = () => {
  const form = useForm({})
  const createProject = trpc.dashboard.projects.create.useMutation()
  const { handleProjectCreatedSuccess, handleProjectCreatedFailure } = useNewProjectCreation()

  const onSubmit = React.useCallback((values: ICreateProjectInputs) => {
    return createProject.mutateAsync(values).then(handleProjectCreatedSuccess).catch(handleProjectCreatedFailure)
  }, [])

  return (
    <Page title="Create a Project" description="Use projects to organize resources" requiresProject={false}>
      <BetterForm.Provider onSubmit={onSubmit} form={form}>
        <BetterForm.Field
          name="project.name"
          description="Something helpful"
          label={'Project Name'}
          placeholder="Production Website"
          autoFocus
          required={true}
        />

        <Button type="submit" disabled={createProject.isLoading} className="relative">
          <Spinner
            className={clsx({
              'opacity-100': createProject.isLoading,
              'opacity-0': !createProject.isLoading,
              absolute: true,
            })}
          />
          <div
            className={clsx({
              'opacity-100': !createProject.isLoading,
              'opacity-0': createProject.isLoading,
            })}
          >
            Create Project
          </div>
        </Button>
      </BetterForm.Provider>
    </Page>
  )
}

export default NewProjectPage
