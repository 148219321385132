import { Page } from '@/components/page'
import { namedURLs } from '@/lib/named-routes'
import { type DestinationTypeConfig, allDestinationTypes } from 'common'
import { Link } from 'react-router-dom'
import { CardGrid, DestinationLogo, ItemCard, type RFC } from 'ui'

export const ListDestinationsPage = () => <Page title="List Destinations">hi</Page>

const DestinationCard: RFC<{ option: DestinationTypeConfig }> = ({ option, className, ...props }) => {
  const itemCardContent = (
    <ItemCard
      icon={<DestinationLogo variant="base" destinationType={option.id} />}
      title={option.name}
      id={option.id}
      description={option.description}
      disabled={!option.available}
      {...props}
    />
  )
  return (
    <>
      {option.available ? (
        <Link draggable={false} to={namedURLs.destinations.new({ destinationID: option.id })}>
          {itemCardContent}
        </Link>
      ) : (
        itemCardContent
      )}
    </>
  )
}

const DestinationsGrid: RFC<{ items: Array<DestinationTypeConfig> }> = ({
  className = undefined,
  children = undefined,
  items = [],
  ...props
}) => {
  return (
    <CardGrid {...props}>
      {items.map((option) => (
        <DestinationCard option={option} key={option.id} />
      ))}
    </CardGrid>
  )
}

const NewDestinationPage = () => {
  const available = Object.values(allDestinationTypes).filter((item) => item.available)
  const comingSoon = Object.values(allDestinationTypes).filter((item) => !item.available)
  return (
    <Page className="space-y-8 subpixel-antialiased" title="New Destination">
      <div className="space-y-4">
        <h3>Available</h3>
        <DestinationsGrid items={available} />
      </div>
      <hr />
      <div className="space-y-4">
        <h3>Coming Soon</h3>
        <DestinationsGrid items={comingSoon} />
      </div>
    </Page>
  )
}

export default NewDestinationPage
