import type { RFC } from 'common-web'
import React, { forwardRef } from 'react'
import type { SVGAttributes } from 'react'

export type SvgProps = Omit<SVGAttributes<HTMLOrSVGElement>, 'xmlns'>

export const Svg: RFC<SvgProps> = forwardRef<SVGSVGElement, SvgProps>((props, ref) => (
  <svg xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    {props.children}
  </svg>
))
