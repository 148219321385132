import { Page, useTabbedNavigation, useUrlSearchParams } from '@/components'
import { trpc } from '@/lib'
import type { InferLoaderData } from '@/trpc-client'
import { Tab } from '@headlessui/react'
import { createColumnHelper } from '@tanstack/react-table'
import clsx from 'clsx'
import type { PageWithLoader, RFC } from 'common-web'
import { getProjectIdFromCookie } from 'common/src'
import { Plus } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { type Params, useLoaderData, useNavigate } from 'react-router-dom'
import { BetterForm, BetterTable, Button, SelectInput, TabNav, zodResolver } from 'ui'
import { Input } from 'ui/src'
import 'prismjs/themes/prism.css' //Example style, you can use another
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-yaml'
import {
  type CreateLinkItemParams,
  type CreateLinkParams,
  type PreviewFormParams,
  previewFormParams,
} from 'chainpipe-api/src/trpc/pipes/types'
import Editor from 'react-simple-code-editor'

async function loader({ params, request }: { params: Params<'id'>; request }) {
  const url = new URL(request.url)
  const sourceId = url.searchParams.get('sourceId')
  const destinationId = url.searchParams.get('destinationId')

  return {
    projectId: getProjectIdFromCookie(document.cookie),
    id: params.id,
    sourceId,
    destinationId,
  }
}

const tabs = [
  { label: 'Table', id: 'table' },
  // { label: 'Code', id: 'code' },
]

const NewLinkPage: PageWithLoader = ({ className = undefined, ...props }) => {
  const navigate = useNavigate()
  const { projectId, sourceId, destinationId } = useLoaderData() as InferLoaderData<typeof loader>

  const { getSearchParams, setSearchParams } = useUrlSearchParams()
  const { currentTab, currentTabIndex, handleTabChange } = useTabbedNavigation(tabs)

  const { data: allSources } = trpc.sources.list.useQuery(
    { projectId },
    {
      placeholderData: [],
    },
  )
  const { data: allDestinations } = trpc.destinations.list.useQuery(
    { projectId },
    {
      placeholderData: [],
    },
  )

  const trpcUtils = trpc.useUtils()

  const step1Form = useForm<PreviewFormParams>({
    defaultValues: {
      managedPostgresServer: {
        id: destinationId || undefined,
      },
      evmContractIndexingClaim: {
        id: sourceId || undefined,
      },
      viewPrefix: '',
    },
    resolver: zodResolver(previewFormParams),
  })

  const [links, setLinks] = useState<CreateLinkItemParams[]>([])
  const [destId, setDestId] = useState('')
  const [code, setCode] = useState<string>('')

  const handleSubmitStep1 = (values: PreviewFormParams) => {
    setSearchParams({ sourceId: values.evmContractIndexingClaim.id, destinationId: values.managedPostgresServer.id })
    setDestId(values.managedPostgresServer.id)
    trpcUtils.linksRouter.evmContractAbiFragmentIndexingClaimToManagedPostgresView.preview
      .fetch({
        projectId,
        ...values,
      })
      .then((links) => {
        setLinks(links)

        console.info(links)
        // setCode(links.code)
      })
  }

  const handleSubmitStep2 = (values: CreateLinkParams) => {
    return trpcUtils.client.linksRouter.evmContractAbiFragmentIndexingClaimToManagedPostgresView.create
      .mutate({
        projectId,
        managedPostgresServer: {
          id: values.managedPostgresServer.id,
        },
        links: values.links,
      })
      .then((result) => {
        navigate('/links')
      })
  }

  return (
    <Page title="New Link" className={clsx(className, '')} {...props}>
      <div className="-mx-lg">
        <div className={clsx('mx-lg max-w-full mb-xl')}>
          <BetterForm.Provider form={step1Form} onSubmit={handleSubmitStep1}>
            <div className="space-y-md">
              <h3>Step 1. Select Contract and Database</h3>
              <div className="grid gap-md grid-cols-12 w-full items-end content-between">
                <div className="col-span-3">
                  <BetterForm.Field
                    name="evmContractIndexingClaim.id"
                    component={SelectInput}
                    options={(allSources || []).map((g) => ({ label: `${g.label} (${g.sourceType})`, value: g.id }))}
                    label="Source"
                  />
                </div>
                <div className="col-span-3">
                  <BetterForm.Field
                    name="managedPostgresServer.id"
                    options={(allDestinations || []).map((g) => ({ label: g.label, value: g.id }))}
                    component={SelectInput}
                    label="Destination"
                  />
                </div>
                <div className="col-span-3">
                  <BetterForm.Field name="viewPrefix" component={Input} label="Table prefix" />
                </div>
                <div className="col-span-3">
                  <Button type="submit">Preview Tables</Button>
                </div>
              </div>
            </div>
          </BetterForm.Provider>
        </div>
        <hr />
        <div className="flex justify-between px-lg mt-xl w-full">
          <div>
            <h3>Step 2. Specify Table Mappings</h3>
            <p>Sorry this form sucks, I promise we will make it better.</p>
          </div>
        </div>
        <Tab.Group selectedIndex={currentTabIndex} onChange={(index) => handleTabChange(tabs[index].id)}>
          <Tab.List as={TabNav.Menu} className="w-full px-lg mt-md flex">
            {tabs.map((tab, index) => (
              <Tab
                as={TabNav.Tab}
                key={tab.id}
                selected={currentTabIndex === index}
                className="focus:outline-none text-sm"
              >
                {tab.label}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Step2Form managedPostgresServerId={destId} onSubmit={handleSubmitStep2} links={links} />
          </Tab.Panels>
        </Tab.Group>
        <hr />
      </div>
    </Page>
  )
}

NewLinkPage.loader = loader

export default NewLinkPage

const columnHelper = createColumnHelper<CreateLinkItemParams>()
const columns = [
  columnHelper.display({
    id: 'name',
    header: 'Name',
    cell: (info) => <div className="font-mono">{info.row.original.evmContractAbiFragmentIndexingClaim.fullName}</div>,
  }),
  columnHelper.display({
    id: 'tableName',
    header: 'View Name',
    cell: (info) => {
      return (
        <BetterForm.Field
          name={`links.${info.row.index}.tableName`}
          component={Input}
          type="text"
          className="font-mono w-full min-w-[400px]"
        />
      )
    },
  }),
]

const CodeEditor: RFC<{ value: string; onValueChange: (val: string) => void }> = ({
  className = undefined,
  ...props
}) => {
  return (
    <Editor
      highlight={(code) => highlight(code || '', languages.yaml)}
      className={clsx(className, 'font-mono')}
      padding={10}
      style={{
        fontFamily: '"Fira code", "Fira Mono", monospace',
        fontSize: 14,
        fontSmooth: 'subpixel-antialiased',
      }}
      {...props}
    />
  )
}

const Step2Form: RFC<{
  links: Array<CreateLinkItemParams>
  managedPostgresServerId: string
  onSubmit: (params: CreateLinkParams) => void
}> = ({ className = undefined, links = [], managedPostgresServerId, onSubmit, ...props }) => {
  const step2Form = useForm({
    // resolver: zodResolver(createLinkParams),
    defaultValues: {
      links,
      managedPostgresServer: {
        id: managedPostgresServerId,
      },
    },
  })
  useEffect(() => {
    step2Form.setValue('links', links)
  }, [links])
  useEffect(() => {
    step2Form.setValue('managedPostgresServer.id', managedPostgresServerId)
  }, [managedPostgresServerId])
  const { fields, append, remove } = useFieldArray({
    control: step2Form.control,
    name: 'links',
    keyName: 'evmContractAbiFragmentIndexingClaim.id',
  })

  return (
    <BetterForm.Provider form={step2Form} onSubmit={onSubmit}>
      <input type="hidden" name="managedPostgresServer.id" value={managedPostgresServerId} />
      <Tab.Panel id="step2" className="py-md mx-lg">
        <div className={clsx(className)} {...props}>
          <BetterTable
            className={clsx('gap-x-sm gap-y-lg mt-lg', className)}
            columns={columns}
            {...props}
            data={fields}
          />
        </div>
      </Tab.Panel>
      <Tab.Panel id="step2-code" className="px-lg py-md">
        {/* <CodeEditor
            value={code}
            onValueChange={() => {
              console.info('We will implement this soon.')
            }}
          /> */}
      </Tab.Panel>
      <div className="my-lg mx-lg flex justify-end">
        <Button
          // isLoading={false}
          // isLoading={state.isSubmitting}
          // loadingText="Creating Views"
          type="submit"
          variant={'blue'}
          leftIcon={<Plus className="w-4 h-4" />}
        >
          Create Views
        </Button>
      </div>
      <br />
      <br />
    </BetterForm.Provider>
  )
}
