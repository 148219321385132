'use client'

import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import React from 'react'
import { forwardRef } from 'react'
import { cn } from '../utils'

export const variant = {
  default:
    'bg-gradient-to-b shadow-sm from-slate-100/70 to-slate-100/95 border border-slate-200 text-slate-800 hover:bg-slate-100 text-shadow-sm hover:text-shadow-none font-medium',
  secondary: 'bg-slate-50 border border-slate-200 text-slate-900 hover:bg-slate-100',
  dark: 'bg-gray-800  font-medium shadow-sm hover:shadow active:shadow-inner text-white hover:bg-slate-800/90 active:bg-slate-900 active:shadow-inner',
  white: 'bg-white border border-slate-200 text-slate-900 hover:bg-slate-50',
  blue: 'bg-blue-600 shadow-sm text-white hover:shadow-md hover:bg-blue-600/95 active:bg-blue-700',
  destructive: 'bg-red-500 text-white hover:bg-red-500/80',
  outline: 'bg-white border border-slate-200 text-slate-900 hover:bg-slate-50',
  ghost: 'hover:bg-accent hover:text-accent-foreground',
  green: 'bg-green-500 hover:bg-green-500/80 active:bg-green-600 text-white font-medium',
  indigo:
    'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 text-white focus:outline-none focus:ring-2 focus:ring-offset-2',
  link: 'text-slate-900 underline-offset-4 hover:underline',
  disabled: '',
}

const buttonVariants = cva(
  'subpixel-antialiased inline-flex font-medium items-center justify-center hover:shadow-sm active:shadow-inner whitespace-nowrap rounded-md text-sm font-medium ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 ease-in-out',
  {
    variants: {
      variant,
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-12 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  label?: string | React.ReactNode
  leftIcon?: React.ReactNode // Added leftIcon prop
  rightIcon?: React.ReactNode // Added rightIcon prop
  disabled?: boolean
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      disabled = false,
      leftIcon = undefined,
      rightIcon = undefined,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        draggable={false}
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={disabled}
        {...props}
      >
        <div className="select-none inline-flex items-center">
          {leftIcon && <span className="mr-1">{leftIcon}</span>}
          {props.label || props.children}
          {rightIcon && <span className="ml-2">{rightIcon}</span>}
        </div>
      </Comp>
    )
  },
)

export { Button, buttonVariants }
