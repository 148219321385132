'use client'

import type * as SelectPrimitive from '@radix-ui/react-select'
import clsx from 'clsx'
import type { OptionType } from 'common-web'
import React from 'react'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../primitives'

export type SelectInputProps<T extends string> = {
  options: Array<OptionType<T>>
  onChange?: (value: T) => void
  defaultValue?: T
  placeholder?: string
  value?: T
  renderRow?: (opt: OptionType<T>) => React.ReactNode
  className?: any
}

export const SelectInput = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Trigger>, SelectInputProps<string>>(
  (params, ref) => {
    const { options, className, placeholder, onChange, defaultValue, value, renderRow = undefined, ...props } = params

    return (
      <Select onValueChange={onChange} defaultValue={defaultValue} value={value}>
        <SelectTrigger ref={ref} className={clsx('bg-white text-slate-900', className)} {...props}>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {(options || []).map(({ value, label, ...rest }) => (
              <SelectItem value={value} key={value}>
                {/* We pass in rest explicitly in case we want to pass down other things, like icons */}
                {renderRow ? renderRow({ value, label, ...rest }) : label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    )
  },
)
