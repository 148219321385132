import clsx from 'clsx'
import type { NetworkID } from 'common'
import type { RFC } from 'common-web'
import React, { forwardRef } from 'react'

import { createElement, useMemo } from 'react'
import { Arbitrum } from './logos/arbitrum'
import { Avalanche } from './logos/avalanche'
import { Base } from './logos/base'
import { Blast } from './logos/blast'
import { Bsc } from './logos/bsc'
import { Celo } from './logos/celo'
import { Cyber } from './logos/cyber'
import { Ethereum } from './logos/ethereum'
import { Fantom } from './logos/fantom'
import { Optimism } from './logos/optimism'
import { Polygon } from './logos/polygon'
import { Scroll } from './logos/scroll'
import { Solana } from './logos/solana'
import { Zora } from './logos/zora'

const networkLogoMap: Partial<Record<NetworkID, React.ElementType>> = {
  'arbitrum-mainnet': Arbitrum,
  'arbitrum-sepolia': Arbitrum,
  'avalanche-mainnet': Avalanche,
  'base-goerli': Base,
  'base-mainnet': Base,
  'base-sepolia': Base,
  'bsc-mainnet': Bsc,
  'blast-mainnet': Blast,
  'blast-sepolia': Blast,
  'celo-mainnet': Celo,
  'cyber-mainnet': Cyber,
  'cyber-sepolia': Cyber,
  'ethereum-goerli': Ethereum,
  'ethereum-mainnet': Ethereum,
  'ethereum-rinkeby': Ethereum,
  'ethereum-sepolia': Ethereum,
  'fantom-mainnet': Fantom,
  'optimism-mainnet': Optimism,
  'polygon-mainnet': Polygon,
  'scroll-mainnet': Scroll,
  'scroll-sepolia': Scroll,
  'solana-mainnet': Solana,
  'zora-mainnet': Zora,
}

const Missing = () => <div />

const sizes = {
  xs: 'w-2 h-2',
  sm: 'w-4 h-4',
  md: 'w-8 h-8',
  lg: 'w-12 h-12',
}

export const NetworkIcon: RFC<{ network: NetworkID; size?: keyof typeof sizes }> = forwardRef(
  ({ network, size = 'sm', className, ...props }, ref) => {
    const Icon = useMemo(() => networkLogoMap[network], [network])

    return createElement(Icon || Missing, {
      ...props,
      ref,
      className: clsx(className, sizes[size]),
    })
  },
)
