import React from 'react'
import type { RFC } from 'common-web'
import { Svg, type SvgProps } from '../../../primitives'

export const Fantom: RFC<SvgProps> = (props) => (
  <Svg width="24" height="24" viewBox="0 0 50 50" {...props}>
    <path
      fill="#3268F6"
      d="M23.81.479c1.216-.639 3.065-.639 4.28 0l12.4 6.516c.732.385 1.134.958 1.206 1.55h.012V41.3c-.016.645-.422 1.286-1.217 1.705l-12.4 6.516c-1.216.639-3.065.639-4.28 0l-12.4-6.516c-.793-.417-1.173-1.063-1.192-1.705a2.37 2.37 0 010-.16V8.755a1.635 1.635 0 01-.001-.106v-.104h.006c.055-.598.44-1.157 1.186-1.55L23.81.48zm15.946 26.34l-11.666 6.13c-1.215.639-3.064.639-4.28 0l-11.64-6.117v14.403l11.64 6.085c.658.35 1.34.69 2.007.733l.134.005c.695.002 1.37-.35 2.054-.681l11.751-6.194V26.819zM8.202 40.588c0 1.255.146 2.08.437 2.66.242.482.604.85 1.265 1.298l.037.026c.145.097.305.199.5.319l.23.14.703.422-1.01 1.662-.788-.474-.133-.08c-.228-.14-.416-.26-.593-.38-1.883-1.263-2.586-2.64-2.6-5.506v-.087h1.952zm16.772-22.549c-.09.031-.175.067-.252.107l-12.4 6.517a1.229 1.229 0 00-.037.02l-.01.006.019.011.028.016 12.4 6.516c.077.04.162.076.252.107v-13.3zm1.953 0v13.3c.09-.03.175-.066.252-.107l12.4-6.516.037-.02.01-.007-.019-.01-.028-.016-12.4-6.517a1.787 1.787 0 00-.252-.107zm12.83-7.17l-11.125 5.845 11.124 5.846V10.868zm-27.587.013v11.664l11.098-5.832-11.098-5.832zM27.18 2.196c-.645-.339-1.812-.339-2.457 0l-12.4 6.517a1.352 1.352 0 00-.037.02l-.01.006.019.011.028.015 12.4 6.517c.645.339 1.812.339 2.457 0l12.4-6.517.037-.02.01-.006-.019-.011-.028-.015-12.4-6.517zm14.41.715l.788.473.133.081c.228.14.416.26.593.379 1.883 1.263 2.586 2.641 2.6 5.507v.087h-1.952c0-1.255-.146-2.08-.437-2.66-.242-.482-.604-.85-1.265-1.298l-.037-.026c-.145-.097-.305-.2-.5-.319l-.23-.14-.703-.423 1.01-1.661z"
    ></path>
  </Svg>
)
