import { Page } from '@/components'
import { type RouterInputs, type RouterOutputs, trpc } from '@/lib'
import React from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import {
  BetterForm,
  Button,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  Input,
  type RFC,
  betterToast,
  useCopyToClipboard,
} from 'ui'
import { useProjectId } from '../../../contexts'

type IHandleSubmitWithoutProject = Required<Omit<RouterInputs['dashboard']['apiKeys']['update'], 'projectId'>>

type IApiKeysShowResponse = RouterOutputs['dashboard']['apiKeys']['show']

const OriginInputList = ({
  fields,
  remove,
  control,
}: Pick<ReturnType<typeof useFieldArray>, 'fields' | 'remove'> & { control: any }) => {
  return (
    <>
      <div className="max-w-xl gap-y-lg">
        {fields.map((field, index) => (
          <div key={field.id} className="flex gap-x-2 mb-2">
            <FormField
              control={control}
              name={`origins[${index}]`}
              render={({ field }) => <Input {...field} placeholder="Enter origin" />}
            />
            <Button variant="destructive" type="button" onClick={() => remove(index)}>
              Remove
            </Button>
          </div>
        ))}
      </div>
    </>
  )
}

const ShowApiKeyPage: RFC<{ apiKey: IApiKeysShowResponse; onSubmit: any }> = ({ apiKey, onSubmit }) => {
  type G = Pick<IApiKeysShowResponse, 'origins'>

  const form = useForm<G>({
    defaultValues: {
      ...apiKey,
    },
  })

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    // @ts-expect-error
    name: 'origins',
  })

  const { copied, copy } = useCopyToClipboard(apiKey.key || '')

  const handleCopy = React.useCallback(() => {
    copy()
    betterToast.success('API Key copied to clipboard')
  }, [copy])

  return (
    <Page title={apiKey?.name || 'API Key'}>
      <BetterForm.Provider form={form} onSubmit={onSubmit}>
        <FormItem>
          <FormLabel>API Key</FormLabel>
          <FormControl>
            <div onClick={handleCopy}>
              <div className="cursor-pointer hover:bg-slate-200 shadow-inner transition-all text-xl font-mono bg-slate-100 rounded-xl p-md inline-block">
                {apiKey.key}
              </div>
            </div>
          </FormControl>
          <FormDescription>Click to copy</FormDescription>
        </FormItem>

        <BetterForm.Field name="name" label="API Key Name" description="A name to easily remember this key." />

        <FormItem>
          <FormLabel>Allowed Origins</FormLabel>
          <FormDescription>Requests only be accepted from these domains or localhost.</FormDescription>
          <Button type="button" variant="white" onClick={() => append('')}>
            Add Origin
          </Button>

          <OriginInputList fields={fields} remove={remove} control={form.control} />
        </FormItem>

        <Button type="submit">Save API Key</Button>
      </BetterForm.Provider>
    </Page>
  )
}

const ShowApiKeyPageWrapper = () => {
  const mutate = trpc.dashboard.apiKeys.update.useMutation()
  const { id } = useParams()
  const projectId = useProjectId()

  const { data: apiKey, refetch } = trpc.dashboard.apiKeys.show.useQuery(
    {
      projectId: projectId!,
      id: id!,
    },
    { enabled: !!projectId && !!id },
  )

  const handleSubmit = React.useCallback(
    (values: IHandleSubmitWithoutProject) =>
      mutate
        // @ts-ignore
        .mutateAsync({
          ...values,
          projectId,
        })
        .then((_result) => {
          refetch()
        }),
    [projectId],
  )
  return apiKey ? <ShowApiKeyPage apiKey={apiKey} onSubmit={handleSubmit} /> : null
}

export default ShowApiKeyPageWrapper
