import { useMemo } from 'react'
import { useUrlSearchParam, useUrlSearchParams } from './use-url-search-params'

type Tab = { label: string; id: string }

export function useTabbedNavigation(tabs: Array<Tab>) {
  const { getSearchParams, setSearchParams } = useUrlSearchParams()
  const initialTab = useUrlSearchParam('tab')

  const currentTab = initialTab || tabs[0].id
  const currentTabIndex = useMemo(() => tabs.findIndex((tab) => tab.id === currentTab), [currentTab])

  const handleTabChange = (tabId: string) => {
    setSearchParams({
      tab: tabId,
    })
  }

  return { currentTabIndex, currentTab, handleTabChange }
}
