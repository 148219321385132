import { z } from 'zod'

export const objectId = z.object({ id: z.string() })

export const createLinkItemParams = z.object({
  tableName: z.string(),
  // id: z.string(),
  evmContractAbiFragmentIndexingClaim: z.object({
    id: z.string(),
    fullName: z.string(),
  }),
})

export type CreateLinkItemParams = z.infer<typeof createLinkItemParams>

export const createLinkParams = z.object({
  managedPostgresServer: objectId,
  links: z.array(createLinkItemParams),
})

export type CreateLinkParams = z.infer<typeof createLinkParams>

export const previewFormParams = z.object({
  managedPostgresServer: objectId,
  evmContractIndexingClaim: objectId,
  viewPrefix: z.string(),
})

export const createLinksParams = {}

export type PreviewFormParams = z.infer<typeof previewFormParams>
