import React from 'react'
import type { RFC } from 'common-web'
import { Svg, type SvgProps } from '../../../primitives'

export const Optimism: RFC<SvgProps> = (props) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" fill="#FF0420" />
      <path
        d="M8.5 15.187c-.714 0-1.3-.168-1.756-.504-.451-.34-.677-.83-.677-1.459 0-.134.015-.293.043-.485.077-.432.188-.95.332-1.56.408-1.651 1.464-2.477 3.163-2.477.46 0 .878.077 1.243.236.365.148.653.379.864.686.211.302.317.662.317 1.08 0 .125-.014.283-.043.475a19.478 19.478 0 0 1-.327 1.56c-.21.821-.57 1.44-1.09 1.848-.513.403-1.204.6-2.068.6Zm.13-1.296c.336 0 .62-.1.855-.297.24-.197.413-.5.513-.912.14-.567.245-1.056.317-1.479.024-.125.039-.254.039-.389 0-.547-.284-.82-.855-.82-.336 0-.624.1-.864.297-.235.197-.403.5-.504.912-.11.403-.216.893-.326 1.479-.024.12-.039.244-.039.379-.004.557.288.83.864.83ZM12.446 15.1c-.067 0-.115-.018-.153-.062-.029-.048-.039-.1-.029-.163l1.243-5.856a.24.24 0 0 1 .101-.163.264.264 0 0 1 .173-.062h2.395c.667 0 1.2.139 1.603.412.408.279.615.677.615 1.2 0 .15-.02.308-.053.47-.149.692-.451 1.2-.912 1.532-.451.331-1.07.494-1.858.494h-1.214l-.413 1.973a.258.258 0 0 1-.1.163.264.264 0 0 1-.174.063h-1.224Zm3.188-3.44c.254 0 .47-.068.657-.207a.987.987 0 0 0 .38-.595c.019-.101.028-.192.028-.27 0-.172-.053-.306-.153-.398-.101-.096-.279-.144-.524-.144h-1.08l-.34 1.613h1.032Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </Svg>
)
