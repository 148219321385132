import { createColumnHelper } from '@tanstack/react-table'
import clsx from 'clsx'
import type { RFC } from 'common-web'
import type { JsonFragment } from 'ethers'
import { BetterTable } from 'ui'

interface Props {
  fragments: Array<JsonFragment>
}

const columnHelper = createColumnHelper<JsonFragment>()

const columns = [
  columnHelper.display({
    header: 'Name',
    cell: (info) => <div className="font-mono">{info.row.original.name}</div>,
  }),
  columnHelper.display({
    header: 'Type',
    cell: (info) => <div className="font-mono">{info.row.original.type}</div>,
  }),
  columnHelper.display({
    header: 'Definition',
    cell: (info) => <div className="font-mono text-sm">{JSON.stringify(info.row.original)}</div>,
  }),
]

export const ContractAbiTab: RFC<Props> = ({ className = undefined, fragments = [], ...props }) => {
  return (
    <div className={clsx(className)} {...props}>
      <BetterTable data={fragments} columns={columns} />
    </div>
  )
}
