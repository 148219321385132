export enum SourceTypeId {
  EVM_CONTRACT_INDEXING_CLAIM = 'evm-contract-indexing-claim',
  EVM_EOA = 'evm-eoa-v1',
  OFFCHAIN_NFT_METADATA = 'offchain-nft-metadata',
  NFT_MARKETPLACE = 'nft-marketplace-v1',
  FARCASTER_HUB = 'farcaster-hub',
}

export type SourceConfig = {
  id: SourceTypeId
  name: string
  available: boolean
  description?: string
  logo?: string
}

export const allSourceTypes = {
  [SourceTypeId.EVM_CONTRACT_INDEXING_CLAIM]: {
    id: SourceTypeId.EVM_CONTRACT_INDEXING_CLAIM,
    name: 'EVM Contract',
    available: true,
    logo: 'chainpipe/source-logos/evm-contract-indexing-claim.svg',
  },
  [SourceTypeId.EVM_EOA]: {
    id: SourceTypeId.EVM_EOA,
    name: 'EVM EOA',
    available: false,
    logo: 'chainpipe/source-logos/evm-eoa-v1.svg',
  },
  [SourceTypeId.OFFCHAIN_NFT_METADATA]: {
    id: SourceTypeId.OFFCHAIN_NFT_METADATA,
    name: 'Offchain NFT Metadata',
    available: false,
    logo: 'chainpipe/source-logos/offchain-nft-metadata.svg',
  },
  [SourceTypeId.NFT_MARKETPLACE]: {
    id: SourceTypeId.NFT_MARKETPLACE,
    name: 'NFT Marketplace Data',
    available: false,
    logo: 'chainpipe/source-logos/nft-marketplace-v1.svg',
  },
  [SourceTypeId.FARCASTER_HUB]: {
    id: SourceTypeId.FARCASTER_HUB,
    name: 'Farcaster Hub',
    available: false,
    logo: 'chainpipe/source-logos/farcaster-hub.svg',
  },
} as const

export enum DestinationTypeId {
  // MANAGED_POSTGRES_TABLE = 'managed-postgres-table',
  MANAGED_UPSTREAM_POSTGRES = 'managed-postgres-upstream',

  GRAPHQL = 'graphql-api-v1',
  REST_API = 'rest-api-v1',
  CSV_API = 'csv-api-v1',
  KAFKA_STREAM = 'kafka-stream-v1',
  GOOGLE_PUBSUB = 'google-pubsub-v1',
  WEBHOOK = 'webhook-v1',
  BIGQUERY = 'bigquery-v1',
}

export type DestinationTypeConfig = {
  id: DestinationTypeId
  name: string
  available: boolean
  description?: string
  logo?: string
}

export const allDestinationTypes = {
  [DestinationTypeId.MANAGED_UPSTREAM_POSTGRES]: {
    id: DestinationTypeId.MANAGED_UPSTREAM_POSTGRES,
    name: 'Postgres Managed Database',
    available: true,
    logo: 'chainpipe/destination-logos/managed-postgres-upstream.svg',
  },

  // [DestinationTypeName.POSTGRES_FDW]: {
  //   id: DestinationTypeName.POSTGRES_FDW,
  //   name: 'Postgres Foreign Data Wrapper',
  //   available: true,
  // },

  [DestinationTypeId.BIGQUERY]: {
    id: DestinationTypeId.BIGQUERY,
    name: 'Google BigQuery',
    available: false,
    logo: 'chainpipe/destination-logos/bigquery-v1.svg',
  },

  [DestinationTypeId.GOOGLE_PUBSUB]: {
    id: DestinationTypeId.GOOGLE_PUBSUB,
    name: 'Google Pubsub',
    available: false,
    logo: 'chainpipe/destination-logos/google-pubsub-v1.svg',
  },

  [DestinationTypeId.WEBHOOK]: {
    id: DestinationTypeId.WEBHOOK,
    name: 'Webhook',
    available: false,
    logo: 'chainpipe/destination-logos/webhook-v1.svg',
  },

  [DestinationTypeId.CSV_API]: {
    name: 'CSV Download Link',
    available: false,
    id: DestinationTypeId.CSV_API,
    logo: 'chainpipe/destination-logos/csv-api-v1.svg',
  },
  [DestinationTypeId.REST_API]: {
    name: 'REST API',
    available: false,
    id: DestinationTypeId.REST_API,
    logo: 'chainpipe/destination-logos/rest-api-v1.svg',
  },
  [DestinationTypeId.GRAPHQL]: {
    name: 'GraphQL API',
    available: false,
    id: DestinationTypeId.GRAPHQL,
    logo: 'chainpipe/destination-logos/graphql-api-v1.svg',
  },
  [DestinationTypeId.KAFKA_STREAM]: {
    name: 'Kafka Stream',
    available: false,
    id: DestinationTypeId.KAFKA_STREAM,
    logo: 'chainpipe/destination-logos/kafka-stream-v1.svg',
  },
} as const
