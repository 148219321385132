import React from 'react'
import { forwardRef } from 'react'
import { cn } from '../utils'

export const Row = forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement> & {
    className?: string
    children?: React.ReactNode
  }
>(({ className, children, ...props }, ref) => {
  return (
    <div ref={ref} className={cn('flex w-full flex-row items-center justify-between', className)} {...props}>
      {children}
    </div>
  )
})
