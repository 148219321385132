'use client'

import * as LabelPrimitive from '@radix-ui/react-label'
import { cva } from 'class-variance-authority'
import type { VariantProps } from 'class-variance-authority'
import React from 'react'
import { forwardRef } from 'react'
import { cn } from '../utils'

const labelVariants = cva(
  'text-base text-gray-700 leading-tight tracking-tight peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
)

const Label = forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
  <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props} />
))

export { Label }
