import React from 'react'
import type { RFC } from 'common-web'
import { Svg, type SvgProps } from '../../../primitives'

export const Celo: RFC<SvgProps> = (props) => (
  <Svg width="24" height="24" viewBox="0 0 950 950" xmlSpace="preserve" {...props}>
    <path
      d="M375 850c151.9 0 275-123.1 275-275S526.9 300 375 300 100 423.1 100 575s123.1 275 275 275zm0 100C167.9 950 0 782.1 0 575s167.9-375 375-375 375 167.9 375 375-167.9 375-375 375z"
      style={{
        fill: '#fbcc5c',
      }}
    />
    <path
      d="M575 650c151.9 0 275-123.1 275-275S726.9 100 575 100 300 223.1 300 375s123.1 275 275 275zm0 100c-207.1 0-375-167.9-375-375S367.9 0 575 0s375 167.9 375 375-167.9 375-375 375z"
      style={{
        fill: '#35d07f',
      }}
    />
    <path
      d="M587.4 750c26-31.5 44.6-68.4 54.5-108.1 39.6-9.9 76.5-28.5 108.1-54.5-1.4 45.9-11.3 91.1-29.2 133.5-42.3 17.8-87.5 27.7-133.4 29.1zM308.1 308.1c-39.6 9.9-76.5 28.5-108.1 54.5 1.4-45.9 11.3-91.1 29.2-133.4 42.3-17.8 87.6-27.7 133.4-29.2-26 31.5-44.6 68.4-54.5 108.1z"
      style={{
        fill: '#5ea33b',
      }}
    />
  </Svg>
)
